<template>
    <div>
        <nav-admin></nav-admin>
        <div class="container">
            <div class="row mt-4 mb-2">
                <div class="col-10">
                    <h2 class="h2">Campaigns</h2>
                </div>
            </div>
            <loading v-if="isEventCustomersLoading" />
            <div class="spiner-wrapper" v-if="getEvents.length == 0 && !isEventCustomersLoading">
                <b>Nothing found</b>
            </div>
            <b-table
                v-if="!isEventCustomersLoading && getEvents.length > 0"
                id="events"
                :items="getEvents"
                :fields="fields"
                :per-page="getEvents.length"
                :current-page="1"
            >
                <template v-slot:cell(title)="data">
                    {{ data.item.template && data.item.template.subject ? data.item.template.subject : "-------" }}
                </template>

                <template v-slot:cell(event_date)="data">
                    {{data.item.is_sent ?  convertBrouserTZDateOnly(data.item.event_date) : null }}
                </template>

                <template v-slot:cell(status)="data">
                    {{data.item.is_sent ?  "sent" : null }}
                </template>
                
                <template v-slot:cell(controls)="data"> 
                    <button @click="openEvent(data.item.id)" class="btn btn-primary float-right">Edit</button>
                </template>
            </b-table>
        </div>
    </div>
</template>
          
<script>
import navAdmin from './../../components/nav-admin';
import loading from './../../components/admin/spiner';
import { notificationMixin } from './../../mixins/notification.mixin';
import { timeZone } from './../../mixins/time-zone.mixin';
import { mapGetters, mapActions } from 'vuex';

export default {
    mixins: [notificationMixin, timeZone],
    components: { navAdmin, loading },
    computed: mapGetters([
        'getEvents',
        'isEventCustomersLoading'
    ]),
    data() {
        return {
            fields: [
                { key: 'title', label: "Title" },
                { key: 'event_date', label: "Event Date" },
                { key: 'status', label: "Status" },
                { key: 'controls', label: "" }
            ]
        }
    },
    methods: {
        ...mapActions(['getEventsAction', 'getEventAction']),
        async openEvent(eventId) {
            await this.getEventAction(eventId);
            this.$router.push({ 
                name: 'EventEditor', 
                params: { 'event_id': eventId }
            });
        }
    },
    async mounted() {
        await this.getEventsAction();
    }
}
</script>